/*================  Google Fonts  ==================*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&family=Poppins:wght@400;500;600&display=swap");

/*================  CSS Variables  ==================*/
:root {
  --header-height: 3rem;

  --body-font: "Poppins", sans-serif;
  /*================  Colors  ==================*/
  --body-color: rgb(250, 250, 250);
  --container-color: #fff;
  --title-color: rgb(51, 51, 51);
  --title-color-dark: rgb(0, 0, 0);
  --text-color: rgb(117, 117, 117);

  /*================  Font  ==================*/
  --big-font-size: 3.5rem;
  --h1-font-size: 2.25rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;
  --smaller-font-size: 0.813rem;
  --tiny-font-size: 0.625rem;

  --font-normal: 400;
  --font-medium: 500;
  --font-semi-bold: 600;

  /*================  Margines  ==================*/
  --m-0-25: 0.25rem;
  --m-0-5: 0.5rem;
  --m-0-75: 0.75rem;
  --m-1: 1rem;
  --m-1-5: 1.5rem;
  --m-2: 2rem;
  --m-2-5: 2.5rem;
  --m-3: 3rem;

  /*================  z index  ==================*/
  --z-tooltip: 10;
  --z-fixed: 100;
  --z-modal: 1000;
}

@media screen and (max-width: 992px) {
  :root {
    --big-font-size: 2.75rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1rem;
    --normal-font-size: 0.938rem;
    --small-font-size: 0.813rem;
    --smaller-font-size: 0.75rem;
  }
}

/*================  Base  ==================*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body,
button,
input,
textarea {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
}

body {
  background-color: var(--body-color);
  color: var(--text-color);
}

h1,
h2,
h3 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
  border: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

/*================  Resuable classes  ==================*/
.main {
  justify-content: center;
  padding: 6rem 0 2rem;
}

.section__title {
  font-size: var(--h1-font-size);
  color: var(--title-color);
  text-align: center;
}

.section__subtitle {
  display: block;
  font-size: var(--small-font-size);
  margin-bottom: 2rem;
  text-align: center;
}

.section__title .section__subtitle {
  text-align: center;
}

/*================  Layout  ==================*/
.container {
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid {
  display: grid;
  gap: 1.5rem;
}
/*================  Buttons  ==================*/
.button {
  display: inline-block;
  background-color: var(--title-color);
  color: var(--container-color);
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: var(--font-medium);
  text-align: center;
  width: auto;
}

.button:hover {
  background-color: var(--title-color-dark);
}

.button__icon {
  margin-left: 0.5rem;
}

/*================  Responsive design  ==================*/
/* For large devices */
@media screen and (max-width: 992px) {
  .container {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  .container {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
