.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 1rem 0;
  background-color: var(--container-color);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.footer__social {
  display: flex;
  justify-content: center;
  column-gap: 1.25rem;
}

.footer__social-link {
  background-color: var(--title-color);
  color: var(--container-color);
  font-size: 1.125rem;
  padding: 0.4rem;
  border-radius: 0.5rem;
  display: inline-flex;
}

.footer__social-link:hover {
  background-color: var(--title-color-dark);
}

/*================  Responsive design  ==================*/
/* For large devices */
@media screen and (max-width: 992px) {
}

/* For medium devices */
@media screen and (max-width: 768px) {
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 3rem 0;
    background-color: var(--container-color);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
