.about {
  align-items: center;
  justify-content: center;
}

.about__info {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  column-gap: 4rem;
  margin-bottom: var(--m-2);
}
.about__box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  text-align: center;
  padding: 1rem 1.25rem;
  min-height: 150px;
}
.about__title {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}
.about__subtitle {
  font-size: var(--small-font-size);
}

/*================  Responsive design  ==================*/
/* For large devices */
@media screen and (max-width: 992px) {
  .about__box {
    padding: 0.75rem 0.5rem;
  }
  .about__info {
    justify-content: center;
  }
  .about__description {
    padding: 0 5rem;
  }
}

/* For medium devices */
@media screen and (max-width: 768px) {
}

@media screen and (max-width: 576px) {
}

/* For small devices */
@media screen and (max-width: 350px) {
  .about__info {
    grid-template-columns: repeat(1, 1fr);
  }
}
