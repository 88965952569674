.home {
  width: 100%;
  height: 100%;
}

.home__content {
  padding-top: 5.5rem;
  display: grid;
  align-items: center;
  justify-content: center;
}
