.skills__content {
  align-items: center;
  justify-content: center;
}

.skills__container {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  column-gap: 2rem;
  justify-content: center;
}

.skills__box {
  background-color: var(--container-color);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1.25rem;
  text-align: center;
  padding: 1rem 1rem;
}
.skill__title {
  font-size: var(--normal-font-size);
}

/*================  Responsive design  ==================*/
/* For medium devices */
@media screen and (max-width: 768px) {
  .skills__container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    column-gap: 1rem;
    row-gap: 1.5rem;
    justify-content: center;
  }
}
/* For small devices */
@media screen and (max-width: 350px) {
  .skills__container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    justify-content: center;
  }
}
