form {
  display: flex;
  flex-direction: column;
  padding: 2rem 1rem;
  margin: auto;
  max-width: 600px;
}

.contact__content{
  padding: 1rem;
}
label
{
    margin-bottom: 0.5rem;
}

form input, form textarea{
    margin-bottom: 0.5rem;
    padding: 10px;
}
